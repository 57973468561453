.light {
  --bg-primary: #f7f6f4;
  --reverse-primary: #f7f6f4;
  --bg-secondary: #ffffff;
  --text-primary: #313132;
  --text-secondary: #f7f6f4;
  --text-accent: #1181b2;
  --selected: #ebebeb;
  --disabled: #f0f2f5;
}
.dark {
  --bg-primary: #f7f6f4;
  --reverse-primary: #f7f6f4;
  --bg-secondary: #f7f6f4;
  --text-primary: #f7f6f4;
  --text-secondary: #f7f6f4;
  --text-accent: #f7f6f4;
  --selected: #ebebeb;
  --disabled: #f0f2f5;
}
.content-wrapper {
  transition: background-color 0.4s;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

#sc12::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

#sc12::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 25px;
}
#sc12 {
  overflow-y: scroll;
  overflow-x: scroll;
  height: 100%;
}
#sc13::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
#sc13::-webkit-scrollbar-thumb {
  background: #94989f;
  border-radius: 25px;
}
#sc13 {
  overflow-y: scroll;
  overflow-x: scroll;
  height: 100%;
}
svg {
  vertical-align: baseline;
}
@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  width: 100%;
  height: 100%;
}

.follow a {
  color: #131313;
  text-decoration: none;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}
.org-tree li::before,
.org-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;

  width: 50%;
  height: 20px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}
.org-tree li:last-child::before {
  border-right: 1px solid #ccc;
}
.org-tree li:last-child::before {
  border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;

  width: 0;
  height: 20px;
}

.org-tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: sans-serif;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.card {
  position: relative;
  padding-top: 50px;
  width: 300px;
}

.card .image {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  z-index: 100;
}
.card .image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 13px solid #96f6b7;
}
.card .card-body {
  background: #f3f3f3;
  height: 56px;
  text-align: center;
  width: 300px;
  padding-top: 60px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}

.card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
}
.card .card-body p {
  line-height: 20px;
  font-size: small;
}
.card .card-footer {
  padding: 3px;
  background: #96f6b7;
  text-align: center;
  bottom: 0;
  position: relative;
  width: 295px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.card .card-footer img {
  width: 25px;
  margin: 5px;
}

.cardsub{
  position: absolute!important;
  bottom: 0!important;
}